/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { Styled } from 'theme-ui'
import styled from '@emotion/styled'
import { MdInfoOutline, MdBuild, MdQuestionAnswer, MdChevronRight } from "react-icons/md";
import { uniqueId, kebabCase } from 'lodash'
import theme from '@giraldomac/gatsby-theme-mmdbase/src/gatsby-plugin-theme-ui'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../../components/elements'

const BoxList = styled.ul`
  margin: 0;
  list-style: none;
  font-size: ${theme.fontSizes[2]};
  li{
    margin: 0;
    line-height: 1.25;
  }
  li a {
    align-items: center;
    color: white;
    display: flex;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    justify-content: space-between;
    padding: 0.35em;
    text-decoration: none;
    transition: background-color .25s;
  }
  li a:hover {
    background-color: rgba(0,0,0,0.1);
  }
`

const Index = ({ data }) => {

  const page = data.prismic.about

  return (
    <Layout>
      <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
      <PubHero title={RichText.asText(page.title)} />
      <Section>
        <Container>
          <Flex flexWrap="wrap">
            {/* Departments */}
            <Box width={["full", "full", 1 / 2]}
              sx={{
                backgroundColor: 'blue.6',
                color: 'white',
              }}>
              <Flex flexWrap="wrap">
                <Box width="full" sx={{ p: 4, backgroundColor: 'blue.5' }}>
                  <Flex justifyContent="space-between" alignItems="center"><Styled.h2 sx={{ color: 'white' }}>Departments</Styled.h2><MdInfoOutline sx={{ fill: 'blue.6', fontSize: '3em' }} /></Flex>
                </Box>
                <Box width="full" sx={{ p: 4 }}>
                  <BoxList>
                    {data.prismic.departments.body.map(({ primary }) => (
                      <li key={`${uniqueId('dept_id_')}`}><Link to={`about/departments#${kebabCase(RichText.asText(primary.title1))}`}>{RichText.asText(primary.title1)} <span sx={{ fontSize: 'inherit' }}><MdChevronRight /></span></Link></li>
                    ))}
                  </BoxList>
                </Box>
              </Flex>
            </Box>

            <Box width={['full', 'full', 1 / 2]}>
              <Flex flexWrap="wrap">
                {/* Leadership */}
                <Box width={["full"]}
                  sx={{
                    backgroundColor: 'green.6',
                    color: 'white',
                  }}>
                  <Flex flexWrap="wrap">
                    <Box width="full" sx={{ p: 4, backgroundColor: 'green.5' }}>
                      <Flex justifyContent="space-between" alignItems="center"><Styled.h2 sx={{ color: 'white' }}>Leadership</Styled.h2><MdQuestionAnswer sx={{ fill: 'green.6', fontSize: '3em' }} /></Flex>
                    </Box>
                    <Box width="full" sx={{ p: 4 }}>
                      <BoxList>
                        {data.prismic.leadership.body.map(({ primary }) => (
                          <React.Fragment key={`${uniqueId('leadership_id_')}`}>
                            {
                              primary.title1 == null ? null :
                                (<li><Link to={`about/leadership#${kebabCase(RichText.asText(primary.title1))}`}>{RichText.asText(primary.title1)} <span sx={{ fontSize: 'inherit' }}><MdChevronRight /></span></Link></li>)
                            }
                          </React.Fragment>
                        ))}
                      </BoxList>
                    </Box>
                  </Flex>
                </Box>

                {/* Facilities & Systems */}
                <Box width={["full"]}
                  sx={{
                    backgroundColor: 'orange.6',
                    color: 'white',
                  }}>
                  <Flex flexWrap="wrap">
                    <Box width="full" sx={{ p: 4, backgroundColor: 'orange.5' }}>
                      <Flex justifyContent="space-between" alignItems="center"><Styled.h2 sx={{ color: 'white' }}>Facilities/Systems</Styled.h2><MdBuild sx={{ fill: 'orange.6', fontSize: '3em' }} /></Flex>
                    </Box>
                    <Box width="full" sx={{ p: 4 }}>
                      <BoxList>
                        {data.prismic.facilitiesSystems.body.map(({ primary }) => (
                          <li key={`${uniqueId('dept_id_')}`}><Link to={`about/facilities-systems#${kebabCase(RichText.asText(primary.title1))}`}>{RichText.asText(primary.title1)} <span sx={{ fontSize: 'inherit' }}><MdChevronRight /></span></Link></li>
                        ))}
                      </BoxList>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>




        </Container>
      </Section>
    </Layout>
  )
}

export default Index

export const query = graphql`
query AboutQuery {
  prismic {
    about(lang: "en-us", uid: "about") {
      title
      content
      meta_title
      meta_description
      _linkType
    }
    departments(lang: "en-us", uid: "departments") {
      body {
        ... on PRISMIC_DepartmentsBodyText {
          primary {
            title1
          }
        }
      }
    }
    leadership(lang: "en-us", uid: "leadership") {
      body {
        ... on PRISMIC_LeadershipBodyText {
          primary {
            title1
          }
        }
      }
    }
    facilitiesSystems(lang: "en-us", uid: "facilities-systems") {
      body {
        ... on PRISMIC_FacilitiesSystemsBodyStats {
          label
          primary {
            title1
          }
        }
      }
    }
    
  }
}
`